<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-13 17:39:56
 * @ Description: Fixer's terms and conditions page in landing section of the app.
 -->

<template>
    <div>
        <v-row
            style="padding-top: 90px; padding-bottom: 5rem;"
            justify="center"
        >
            <!-- Heading -->
            <v-col
                class="text-center pb-6"
                :class="$vuetify.breakpoint.mdAndUp ? 'mont-50-eb' : 'mont-40-eb'"
                cols="12"
                style="color: #2E567A;"
            >
                Terms &amp; Conditions
            </v-col>
            <!-- Heading -->

            <!-- Terms & Conditions -->
            <v-col
                cols="12"
                class="os-14-r elevation-4 pa-2 rounded-lg"
                style="background-color: rgb(249, 251, 253);"
                :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 270px;' : $vuetify.breakpoint.xs ? 'max-width: 320px;' : $vuetify.breakpoint.xs ? 'max-width: 500px;' : $vuetify.breakpoint.md ? 'max-width: 920px;' : 'max-width: 1110px;'"
            >
                <strong>TERMS OF SERVICE</strong> <br />
                <strong>FIXER GLOBAL SERVICES</strong> <br /> <br />

                <p>Welcome to 9to5fixer.com. These Terms of Service contain the terms and conditions that govern all use of Fixer Global Services.</p>
                
                <p>Please read these Terms of Service of Fixer Global Services (“Terms of Service”) and our Privacy Policy for Fixer Global Services (“Privacy Policy”) carefully before using Fixer Global Services.</p>

                <p>
                    Whenever you use Fixer Global Services, you agree to be bound by all of the terms and conditions of these Terms of Service. If you don’t agree to all the terms and conditions you must not use Fixer Global Services.
                </p>

                <p>
                    References to “Fixer Global,” “Us” or “We” means Fixer Global Ltd located at Level 3 Alexander House 35 Cybercity Ebene 72201 Mauritius and any company belonging to the same group of companies with Us.
                </p>

                <p>
                    In all cases, “Fixer Global,” “Us,” or “We” includes our agents, consultants, employees, officers and directors as well as any third-party content providers, distributors, licensees or licensors.
                </p>

                <p>
                    <strong>FIXER GLOBAL GENERAL TERMS AND CONDITIONS</strong>
                </p>

                <p>
                    These General Terms and Conditions shall apply to the provision of Services by Supplier to a Customer under a service agreement signed between the Parties or Supplier’s quotation approved by the Customer (“Service Agreement”) to which these General Terms and Conditions have been attached to.
                    No general terms and conditions of the Customer shall at any time form a part of the content of Service Agreement between Customer and the Supplier, unless expressly agreed by the Supplier in writing.
                </p>

                <strong>1 DEFINITIONS</strong> <br />

                <p></p>

                In addition to any definitions made in the Service Agreement, the following capitalized terms shall have the following meanings: <br />
                &bull; "Application" - Web-based future foresight application of Fixer Global and related Software. <br />
                &bull; "Confidential Information" - Technical, financial and commercial information and data relating to a Party’s or its clients’ respective businesses, including but not limited to Intellectual Property Rights of a Party, whether in tangible or in intangible form. <br />
                &bull; "Content Services" - Services for the collection, analysis and processing of Content. <br />
                &bull; "Content" - Any future foresight information or other data collected by Supplier or third parties for Supplier in whatever form or format from time to time and any document or media containing such information or data. <br />
                &bull; “Customer” - Party identified in the Service Agreement subscribing to the Services provided by the Supplier. <br />
                &bull; "Customer Content" - Any market information or other content collected by Customer or third parties for Customer in whatever form or format from time to time and any document or media containing such information or data. <br />
                &bull; ”Fixer Global” - Fixer Global Ltd, a Mauritian GBC , at Level 3 Alexander House 35 Cybercity Ebene 72201 Mauritius, Business Identity Code GB20025831, the owner of Application and Software. <br />
                &bull; "Intellectual Property Rights" - Patents (including utility models), design patents, rights in designs and other like protection, copyright, business, product and domain name, trademark and any other form of statutory protection, of any kind and applications for any of the foregoing respectively as well as any trade secrets, whether or not capable of registration. <br />
                &bull; "Party” or “Parties" - Supplier and Customer, including their respective affiliates from time to time. <br />
                &bull; "Service Agreement" - The service agreement for the provision of Services by Supplier to Customer, including its Appendices. <br />
                &bull; "Service Fees" - Fees payable by Customer to Supplier under a Service Agreement for the agreed Services. <br />
                &bull; "Service(s)" - All services supplied by Supplier to Customer pursuant to the Service Agreement. <br />
                &bull; "Software" - Standard Fixer Global- software of Fixer Global as updated by Fixer Global from time to time. <br />
                &bull; “Supplier” - Party identified in the Service Agreement, such as Fixer Global or an authorized distributor of Fixer Global. <br />
                &bull; “Third Party Content” – Any content originating from third parties, including information constituting or being used to develop Content. <br />
                &bull; "User" - An individual who has been granted a right to use Application, whether an employee of Customer or a representative of a third-party contractor of Customer, all at the sole and exclusive responsibility of Customer. <br /> <br />

                <p><strong>2 GENERAL OBLIGATIONS OF PARTIES</strong></p>

                <p><strong>2.1 Supplier</strong></p>

                <p>(1) Supplier shall perform the agreed Services with all due skill, diligence, prudence and foresight which would reasonably be expected from a service provider skilled and experienced in the field.</p>

                <p>(2) Supplier agrees to ensure that the Service functions materially as agreed and that the Service does not violate any laws, regulations or regulatory provisions effective from time to time in Mauritius.</p>

                <p>(3) Supplier shall upon reasonable request of Customer provide Customer up to date information concerning Supplier procedures, methods or processes, relating to Application and Services, subject to Supplier and third-party confidentiality restrictions.</p>

                <p><strong>2.2 Customer</strong></p>

                <p>(1) Customer's cooperation and assistance is essential for Supplier to successfully carry out its obligations under the Service Agreement. Customer shall allocate sufficient personnel resources to perform necessary obligations and tasks.</p>
                <p>(2) Customer shall make available to Supplier the items and tools, including access to relevant information sources as specified in Service Agreement or otherwise reasonably required for Supplier’s performance of Services. Supplier shall be responsible for the safe custody of such items while they are in its care.</p>
                <p>(3) Customer shall be responsible at its own cost for obtaining and maintaining any and all necessary equipment, software, systems, applications, devices and data connections required for Customer to use the Service, in accordance with information given by Supplier.</p>
                <p>(4) Customer is obligated to safeguard any of its identifiers and passwords related to the Service. Customer remains responsible and liable for any use of the Service under its identifiers or passwords regardless of the identity of the User. Customer must notify Supplier immediately if there is a reason to suspect or believe that the identifiers or passwords in question have been accessed by an unauthorized third party. Customer is released from its liability for unauthorized use of the Service hereunder after having notified Supplier as provided above.</p>
                <p>(5) Customer shall be solely responsible for any information, instructions and material it has given to Supplier for the purposes of the Service Agreement. Supplier is not liable or responsible for any errors or deficiencies caused by or relating to Customer’s information, instructions and/or material.</p>
                <p>(6) Customer Content shall be developed and used at the sole responsibility of Customer and Customer shall indemnify and hold Supplier harmless of any claim relating to the creation and use of Customer Content, including, but not limited to, any claim of infringement of third-party Intellectual Property Rights. Customer shall ensure that Customer Content and the use thereof does not violate any other law or regulation and that Customer Content meets any other general requirement of appropriateness.</p>

                <p><strong>3 GRANT OF RIGHTS</strong></p>

                <p>(1) The rights are granted either directly by the owner of Application and Software, Fixer Global or by an authorized distributor of Fixer Global, in which case Fixer Global shall have a direct responsibility towards Customer only for such obligations as expressly specified herein. Supplier who is a distributor of Fixer Global warrants that it has all rights necessary from Fixer Global to grant the rights specified herein.</p>
                <p>(2) During the term of the Service Agreement and upon payment of Service Fees, Supplier grants to Customer a limited, non-exclusive, non-transferable right to access to the Application, including Software, subject to any limitations in Service Agreement, solely for Customer’s and its affiliates’ internal business operations. Customer may only allow third parties access to Application or Software subject to an express written permission of Supplier. The number of authorized Users shall be specified in the Service Agreement.</p>
                <p>(3) During the term of the Service Agreement and upon payment of Service Fees related to Content Services, Supplier grants to Customer a limited, non-exclusive and non-transferable right to use the Content provided by Supplier pursuant to Service Agreement, solely for Customer’s and its affiliates’ internal business operations. If there are additional terms of use or license terms to Third Party Content that have been issued by the third party, Supplier shall guide Customer on the specific usage and other license rights. Customer shall comply with such use or license terms relating to third-party rights of which Customer has been made aware of.</p>
                <p>(4) Customer shall not directly or indirectly reverse engineer, decompile, disassemble or otherwise attempt to discover the source code or underlying ideas or algorithms of Application; copy, modify, translate, or create derivative works based on Application; rent, lease, distribute, sell, resell, assign, or otherwise transfer rights to Application or Content; use Application for timesharing or service bureau purposes or otherwise for the benefit of a third party; or remove any proprietary notices or labels on Application. The above restriction shall also apply to Customer having a third party perform any of the above on its behalf or under Customer instructions.</p>
                <p>(5) Customer and Users shall abide with any use restrictions set out in the Terms of Service available at <a href="https://www.9to5fixer.co.za/#/terms">Terms of Service</a></p>

                <p><strong>4 PROVISION AND USE OF THE SERVICE</strong> <br /></p>

                <p><strong>4.1 Service Environment, Updates and Support</strong></p>

                <p>(1) Fixer Global shall operate and maintain Application in its own technical environment or in an environment hosted for the Supplier by a third party, making it available to the Customer on a "Software as a Service" basis.</p>
                <p>(2) Fixer Global shall be responsible for the maintenance of Application and shall have a right to update Application with such fixes, service packs, new releases and versions as it sees fit, subject to retaining Application functionality and agreed service levels.</p>
                <p>(3) Supplier shall provide Customer with technical support following Fixer Global’s standard document “Fixer Global Technical Support Services” made available to Customer upon request, and as updated from time to time by and at the discretion of Fixer Global.</p>

                <p><strong>4.2 User Management</strong></p>

                <p>(1) Users shall log into Application with individual logon codes and passwords. The connection to Application shall be encrypted using standard SSL encryption.</p>
                <p>(2) Unless otherwise agreed in Service Agreement, Customer shall manage Users, including the creation and management of user accounts for the individual Users and responding to User questions and requests.</p>
                <p>(3) Fixer Global collects certain personal data relating to Users and other Customer’s employees and other representatives in relation to the provision of the Service, which Fixer Global processes as a data controller. Fixer Global is committed to protecting the privacy of all such data subjects, and processes such personal data for the purposes and in the scope described Fixer Global’s Privacy Policy available at [enter link to policy page] For the sake of clarity, it its agreed and understood that the Supplier or Fixer Global does not process personal data of the Customer on behalf of the Customer as a data processor.</p>

                <p><strong>4.3 Service Levels</strong></p>

                <p>(1) Supplier shall be responsible for Application operating as specified in Service Agreement, including, but not limited to ensuring sufficient hardware capacity, electricity, storage media, etc. as well as operation processes such as backups.</p>
                <p>(2) Detailed target service levels applicable to the technical availability of Application shall be specified in Hosting Service Description, available upon request from Supplier and as updated from time to time by and at the discretion of the Fixer Global.</p>
                <p>(3) Access to Application will be available through the Internet, provided, however, that Supplier shall not be responsible for Customer’s inability to access Application due to unavailability of Internet service, failure of communications, or similar reasons. In other cases, Customer may, within thirty (30) days after the end of any calendar month request in writing a reduction of the respective monthly Service Fee for each working day of the preceding calendar month during which it is shown that Application has not been generally accessible to Users due to reasons attributable to Supplier for a continuous period exceeding four (4) working hours. The reduction shall equal five percent (5%) of the monthly Service Fee for each such day. Supplier shall make the respective reduction of monthly Service Fee in the next invoice after the request; provided, however, that no reduction shall be made for requests which cannot be supported with any Service logs, technical records, or other documentation. No reduction shall be given for downtime resulting from planned maintenance.</p>

                <p><strong>4.4 Content Services</strong></p>

                <p>Supplier shall provide Services for the collection, analysis and processing of Content as agreed in Service Agreement or otherwise in writing.</p>

                <p><strong>4.5 Change Management</strong></p>

                <p>(1) Customer may request and Supplier may recommend changes to Services. Parties shall discuss any change upon Customer's request, and Supplier shall advise Customer of the likely impact of any requested change, including any adjustments to Service Fee. Mutually agreed changes shall be set forth in writing signed by both Parties.</p>
                <p>(2) Supplier shall have a right to implement changes to Software and Application to the extent such changes do not have any material adverse effect on the functionality of Application, Customer's use of Application, the cost of Services to Customer, or the quality of Services.</p>
                
                <p><strong>4.6 Suspension of Services</strong></p>
                <p>(1) Supplier may suspend or interrupt the provision of the Service to Customer, either entirely or partly, and without any liability to Customer if: (i) it is necessary for repairs, maintenance or other similar actions, including security updates, in which case Supplier endeavors to notify Customer of the interruption in advance to the extent reasonably possible; (ii) Customer fails to pay any part of undisputed Service Fees after having been notified of the failure by Supplier, it being understood that failure to duly pay the Service Fees is also considered as a material breach; (iii) Customer’s actions or omissions relating to the use of the Service interfere with or prevent the normal operation of the Service or otherwise cause, or are likely to cause, harm, damage or other detrimental effects to the Service, Supplier or other users of the Service; (iv) there are reasons to suspect that Customer’s credentials have been wrongfully disclosed to an unauthorized third party and the Service is being used under such credentials; (v) Customer uses the Service in breach of the Service Agreement and has not remedied the breach without delay after having been notified thereof by Supplier or uses the Service in violation of any applicable laws, regulations or regulatory provisions; or Customer enters into liquidation, is declared bankrupt or is otherwise deemed insolvent, or a petition is filed for any of the foregoing, or Customer ceases its business.</p>
                <p>(2) Supplier endeavors to notify Customer of the interruption as far in advance as reasonably possible or, if advance notification is not possible due to the urgency of the reasons requiring interruption, without undue delay. Suspension of the Service for the reasons set out in Section 4.6(1) does not relieve Customer from its obligation to pay the Service Fees.</p>

                <p><strong>5 LIMITED WARRANTY</strong></p>

                <p>(1) Supplier warrants that Application will function materially as specified in Service Agreement. Supplier shall not warrant that Application is error-free or available to Users without interruption. The sole remedy for any breach of the above warranty is to bring Application into compliance with Service Agreement. In no event shall Supplier be responsible for errors or faults of Application, which are directly and solely caused by: (i) the incorrect use of Application contrary to Supplier’s instructions, or (ii) modifications to Application made by Customer or a third party not authorized by Supplier.</p>
                <p>(2) Supplier warrants that Content is based on information gathered in good faith from information sources that are regarded by Supplier as reliable. Supplier is not in a position to guarantee the completeness or accuracy of Content. Supplier does not warrant that the results of the information screening and analysis work performed by Supplier in the course of the provision of Services is correct or fits for the purposes of Customer. Supplier may, at its sole discretion, correct errors and discrepancies in Content which are noticed by Supplier or notified by the customer. In no event shall Supplier be responsible for any errors or discrepancies in Customer Content or Third-Party Content.</p>
                <p>(3) Supplier hereby expressly disclaims all other express and implied warranties in respect of the Application or Content, including but not limited to, any implied warranties of merchantability or fitness for a particular purpose.</p>

                <p><strong>6 CONFIDENTIALITY</strong></p>

                <p>(1) Neither Party shall disclose to third parties nor use for any purpose other than for the proper fulfilment of the purpose of Service Agreement (“Permitted Purpose”) any Confidential Information of the other Party, received from the other Party ("Disclosing Party") in whatever form under or in connection with Services without the prior written permission of Disclosing Party. Software and Application, as well as the Fixer Global production methods, resources and processes shall always be deemed Confidential Information.</p>
                <P>(2) The confidentiality obligation shall not apply to Confidential Information, which (i) was in the possession of the Party receiving Confidential Information (“Receiving Party”) prior to disclosure as proven by Receiving Party; (i) was in the public domain at the time of disclosure or later became part of the public domain without breach of confidentiality obligations; (ii) is independently developed by Receiving Party without reference to information of Disclosing Party; (iii) was disclosed by a third party without any obligation of confidentiality; or (iv) is required to be disclosed pursuant to law, provided, however, that if legally possible a minimum of ten (10) days written notice shall be provided by Receiving Party to permit Disclosing Party to take such action as it deems appropriate to limit such disclosure.</P>
                <p>(3) Each Party shall limit the access to Confidential Information to those of its personnel for whom such access is necessary for the proper performance of Service Agreement.</p>
                <p>(4) Each Party shall protect the confidentiality of Confidential Information of the other Party at least with the same degree of care as it exercises in respect of its own Confidential Information, but in no event less than reasonable care.</p>
                <p>(5) The obligations set in this Section 6 shall survive for a period of five (5) years after the expiration or termination of Service Agreement.</p>

                <p><strong>7 INTELLECTUAL PROPERTY RIGHTS</strong></p>

                <p><strong>7.1 Supplier’s Material</strong></p>
                <p>(1) All rights, including but not limited to all Intellectual Property Rights, to Application, Services, Software, upgrades, updates, modifications, Content, Supplier’s Confidential Information or copies thereof and all related documentation shall at all times remain solely the exclusive property of Supplier or the respective rights holder, such as Futures Platform. All Intellectual Property Rights developed or gained through the provision of Services and the operation of Application shall automatically vest in and remain the exclusive property of Supplier or Fixer Global, as appropriate in each case. No other rights or licenses except for the right to access and use the Service pursuant to Section 3 is granted to Customer under the Service Agreement.</p>

                <p><strong>7.2 Third-Party Content</strong></p>
                <p>(1) Intellectual Property Rights to Third Party Content shall belong to the respective third party or its licensors. No other rights or licenses except for the limited right to use Third Party Content pursuant to Section 3 is granted to Customer under the Service Agreement.</p>

                <p><strong>7.3 Customer Content</strong></p>
                <p>(1) Title and all Intellectual Property Rights in and to the Customer Content shall at all times remain with Customer.  Supplier shall use Customer Content only for the purposes of fulfilling Service Agreement and no license or other rights are granted to Supplier to such Customer Content. Customer shall be fully responsible for having any rights necessary for the collection, storage and use of Customer Content originating from third parties, including any permission by relevant third parties providing information constituting or being used to develop Customer Content.</p>
                <p>(2) Inclusion of any proprietary trademarks or information of Customer to any configuration of Application shall not imply any transfer of rights to such trademarks or information to Supplier or any third party. Upon the termination or expiration of Service Agreement for any reason Supplier shall cease the use of any such Customer trademarks or information.</p>

                <p><strong>8 IPR INDEMNIFICATION</strong></p>

                <p>(1) Fixer Global shall defend any action brought against Customer if Software infringes any third party copyright, trademark, trade secret or other Intellectual Property Right and shall settle any such action and shall pay such damages, which: (i) a final court of competence orders Customer to pay without right of appeal; or (ii) Customer has with Fixer Global’s written consent paid to a third party as part of a settlement; if the payment ordered by the court or the settlement would be a result of Software breaching a third party Intellectual Property Right</p>
                <p>(2) As a condition to Fixer Global’s obligations under this Section 8, Customer shall: (i) notify Fixer Global promptly in writing as to any event of which Customer has knowledge that would give rise to an indemnity obligation hereunder; (ii) provide reasonable cooperation and assistance to Fixer Global; and (iii) grant Fixer Global full authority to manage the defense or settlement of the claim. Fixer Global shall maintain control and direction of said defense at its expense, provided that Customer, at Customer’s expense, shall have the right to participate in such defense.</p>
                <p>(3) The indemnification obligation specified above shall not apply to any claim for infringement resulting from: (i) the combination, operation or use of Application or Software with any code or programs not developed or supplied by Fixer Global if such claim would have been avoided but for such combination, operation or use; (ii)  the use of Application or Software as modified or enhanced by Customer if such modification or enhancement results in an infringing or violating product or computer program, and if the use of the unmodified Application or Software would have avoided such infringement or violation; or (iii) the use of Application or Software in violation of the provisions of the Service Agreement.</p>
                <p>(4) This Section 8 sets out the exclusive remedy and Fixer Global’s entire liability for infringement of Intellectual Property Rights.</p>

                <p><strong>9 LIMITATION OF LIABILITY</strong></p>

                <p>(1) Neither Party will be liable for any indirect, incidental, collateral, exemplary, punitive or consequential loss, damage, cost or expense, such as business interruption, or any loss of business, anticipated savings, revenue, goodwill or reputation or loss of data ("Loss"), even if they have been advised of the possibility of such Loss.</p>
                <p>(2) The maximum aggregate amount of compensation for any Loss for which a Party may be liable to the other under the Service Agreement shall in the aggregate be limited to one hundred percent (100%) of Service Fees actually paid by Customer to Supplier under the Service Agreement during the 30 day period preceding the incident having caused the Loss.</p>
                <p>(3) The limitation of liability contained in Sections 9(1) and 9(2) shall not apply to any Loss or damage caused by breach of confidentiality obligations, breaches by one Party of the other Party’s Intellectual Property Rights or to any damage caused by willful conduct or gross negligence.</p>
                <p>(4) A Party shall notify the other Party of any claim, with detailed explanation, within thirty (30) days of discovering the event causing the Loss, or otherwise the other Party's liability for compensation will lapse.</p>
                <p>(5) Both Parties shall use their reasonable endeavors to mitigate any Losses.</p>

                <p><strong>10 TERM AND TERMINATION</strong></p>

                <p><strong>10.1 Term</strong></p>

                <p>Unless otherwise agreed in the Service Agreement, the Service Agreement enters into force upon acceptance of the Customer and shall stay in force for an initial period of one (1) months (“Initial Period”). After the Initial Period, the Service Agreement stays in force for consecutive twelve (12) months periods (“Renewal Period”), unless terminated by either Party by giving three (3) months’ written notice prior to the end of the then current period.</p>

                <p><strong>10.2 Termination for Cause</strong></p>

                <p>Either Party may terminate a Service Agreement with an immediate effect by a written notice to the other Party if the other Party commits a material breach of Service Agreement, unless such breach has been remedied (if remediable) by the defaulting Party within thirty (30) days after receiving a written notice from the non-defaulting Party demanding remedy. Customer's failure to pay Service Fees when due shall always be deemed a material breach.</p>

                <p><strong>10.3 Survival of Terms</strong></p>

                <p>Such provisions of Service Agreement which expressly survive the termination of the Service Agreement or which otherwise due to their nature have been intended to survive, shall remain in effect in accordance with their terms following the termination of the Service Agreement for any reason.</p>

                <p><strong>11 MISCELLANEOUS</strong></p>

                <p><strong>11.1 Fees and Payment</strong></p>
                
                <p>(1) Service Fees shall be agreed in the Service Agreement. Unless otherwise agreed, Service fees are paid annually in advance and other fees monthly in arrears on a time and material basis. The payment term is fourteen (14) days net from the date of invoice. Late interest shall accrue in accordance with Finnish Interest Act. Applicable value added tax shall be added to the fees.</p>
                <p>(2) Supplier may change the Service fees and other charges by notifying the Customer at least four (4) months prior to the next Renewal Period. If the Customer does not accept the change, Customer may terminate the Service Agreement to end upon the effective date of change by notifying the Supplier at least three (3) months in advance.</p>
                <p>(3) Supplier has always the right to increase the Service fees and other charges when based on changes in legislation (e.g. a tax increase) or a regulatory provision, in which event the prices will be adjusted accordingly and the new prices become effective as from the effective date of the said change. Supplier informs Customer of any such change in advance.</p>
                
                <p><strong>11.2 Force Majeure</strong></p>

                <p>(1) If performance of contractual obligations of either Party is delayed for reasons beyond its control that the said Party could not reasonably have been required to consider when concluding the Agreement and whose consequences the said Party could not reasonably have prevented, the said Party shall be discharged from the duty to perform the contractual obligations for as long as the said force majeure continues. Force majeure include but are not limited to war (whether declared or not), acts of government or the European Union, export or import prohibitions, acts of terrorism, breakdown or general unavailability of transport, acts of terrorism, general shortages of energy, fire, explosions, accidents, strikes or other concerted actions of workmen, lockouts, sabotage, civil commotion and riots.</p>
                <p>(2) Notwithstanding the foregoing, any payment obligation of Customer shall in any event not be excused for longer than a maximum of fifteen (15) days. Neither Party shall claim damage or any other compensation from the other Party for delays or non-fulfillment of the Service Agreement caused by force majeure.</p>
                <p>(3) In the event the delay or non-performance of either Party (except Customer’s payment default) continues for a period of six (6) weeks due to reasons of force majeure, then either Party shall have the right to terminate the Service Agreement with immediate effect</p>

                <p><strong>11.3 Subcontractors</strong></p>

                <p>Supplier has the right to use subcontractors in the fulfillment of its obligations under in the Service Agreement. Supplier shall be liable for the work of its subcontractors as for its own.</p>

                <p><strong>11.4 Reference Right</strong></p>

                <p>Unless otherwise expressly agreed, Supplier and Fixer Global shall have a right, both during and after the validity of any Service Agreement, to refer to Customer name as reference and use the name and logo of Customer in its marketing communications provided that the exact contents of the cooperation or any details of any specific assignments shall not be specified.</p>

                <p><strong>11.5 Non-Solicitation</strong></p>

                <p>Neither Party shall actively solicit the employment of the other Party’s personnel involved in the provision of Services directly or indirectly during the term of Service Agreement and twelve (12) months thereafter. If a Party or its affiliate directly or indirectly employs any person from the other Party's personnel involved in the provision of Services, it shall pay to the other Party as a recruitment fee a sum equaling six (6) months gross salary of such a person.</p>

                <p><strong>11.6 Amendments</strong></p>

                <p>Supplier or Fixer Global reserve the right to amend these General Terms and Conditions at any time. In this case, Supplier shall notify the Customer of these changes. The changes shall be deemed to be accepted if the Customer does not object within four (4) weeks after receipt of the amendment notification. If the Customer objects the change, Supplier may: (i) terminate the Service Agreement and refund Customer any prepaid Service Fees covering the remainder of the Initial Period or Renewal Period; or (ii) continue to provide the Service in accordance with the terms applicable prior to the change. All other amendments to the Service Agreement must be made in writing and must be accepted and signed by both Parties.</p>

                <p><strong>11.7 Transfer and Assignment</strong></p>

                <p>Supplier shall have the right to transfer any outstanding accounts to a third party with a written notice to Customer. Supplier shall have the right to assign the Service Agreement to another company owned by Supplier or Supplier’s parent company or to a third party to which the relevant business has been transferred to by giving a written notice to Customer.</p>

                <p><strong>11.8 Governing Law and Dispute Resolution</strong></p>

                <p>The Service Agreement shall be governed by and construed in accordance with the laws of Mauritius. The Parties shall attempt to negotiate in good faith any dispute or claim arising out of or in relation to this Service Agreement. Should these negotiations not lead to an amicable outcome, the dispute shall be finally settled by arbitration in accordance with the Arbitration Rules of the Mauritius Chamber of Commerce. The seat of arbitration shall be Port louis, Mauritius and the language of the arbitration shall be English, as may be decided by the Parties. Evidence may be provided in the aforementioned language. Notwithstanding the foregoing, claims for non-payment by Customer may be resolved in the District Court of Mauritius.</p>

                <p><strong>ADDENDUM 1 2022</strong></p>

                <p><strong>12.1 Payment Date</strong></p>

                <p>Your 9to5fixer membership will continue until terminated. To use the 9to5fixer service, you must have Internet access and provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted method of payment, which may be updated from time to time and may include paying through your account with a third party. Unless you cancel your membership before your billing date, you authorize us to charge the membership fee for the next billing cycle to your Payment Method on the 1st day of every month.</p>

                <p><strong>12.2 Payment Methods</strong></p>

                <p>To use the 9to5fixer service, you must provide one or more Payment Methods. You authorize us to charge any Payment Method associated with your account in case your primary Payment Method is declined or no longer available to us for payment of your subscription fee. You remain responsible for any uncollected amounts. Suppose a payment is not settled successfully due to expiration or insufficient funds, and you do not cancel your account. In that case, we may suspend your access to the service until we have successfully charged a valid Payment Method. For some Payment Methods, the issuer may charge you specific fees, such as foreign transaction fees or other fees relating to processing your Payment Method.</p>

                <p><strong>12.3 Cancellations</strong></p>

                <p>You can cancel your 9to5fixer membership at any time, and you will continue to have access to the 9to5fixer service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable, and we do not provide refunds or credits for any partial membership periods or unutilized 9to5fixer content. To cancel, go to the "Account" page and follow the instructions for cancellation. If you cancel your membership, your account will automatically close at the end of your current billing period.</p>

                <p><strong>12.4 Electronic and Multimedia Communications</strong></p>

                <p>We will send you information about your account (e.g., payment authorizations, information, promotions, invoices, changes in password or Payment Method, confirmation messages, and notices) in electronic/multimedia form only. For example, via emails to your email address provided during registration and or via Business What's App and Push Notifications using the app. By accepting these terms, you give consent to receive these messages.</p>

                <p><strong>12.5 90-Day Money-Back</strong></p>

                <p>Should you not get the opportunity to generate income in 90 days using the 9to5fixer platform, providing your profile is complete and active, 9to5fixer will refund the subscription monies paid. Option to generate income includes a functional lead, job application, and or inquiry for service</p>
            </v-col>
            <!-- Terms & Conditions -->
        </v-row>
    </div>
</template>
<script>
    export default {
        name: 'TermsPage'
    }
</script>